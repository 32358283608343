<template>
	<div class="">
		<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :before-close="close" class="seleAddressWap">
			<div slot="title">
				<div class="titleLeft">
					<div class="">
						{{i18n('57')}}
					</div>
				</div>
				<div class="titleRight" @click="close">
					<div class="">
						<img src="@/assets/close.png" alt="">
					</div>
				</div>
			</div>
			<div class="list">
				<div class="" style="height: 16px;width: 100%;">
				
				</div>
				<div class="noData" v-if="noData">
					<div class="">
						<img src="@/assets/noData2.png" alt="">
						<div class="text">
							{{i18n('58')}}
						</div>
					</div>
				</div>
				<div class="" style="margin-bottom: 8px;" v-for="(item,index) in listData" :key="index">
					<item :data='item' @close="close" :seleData="seleData" @seleChange="seleChange" @edit="addOpen(2,item.address.id,item.address.longitude)"></item>
				</div>
			</div>
			<div class="but">
				<div class="butTwo" @click="addOpen(1)">
					{{i18n('229')}}
				</div>
				<div class="butOne" @click="sub">
					{{i18n('56')}}
				</div>
			</div>
		</el-dialog>
		<add ref="add" @czSuccess="czSuccess"></add>
	</div>
	
</template>

<script>
	import {listV2} from "@/api/address.js"
	
	import item from "./items.vue"
	import add from "./add.vue"
	export default {
		name: "seleAddress",
		components: {
			item,
			add
		},
		data() {
			return {
				show: false,
				noData: false,
				listData: [],
				seleData:{
					address:0
				}
			}
		},
		methods: {
			czSuccess(){
				this.open()
			},
			addOpen(type,id,isNew){
				this.close()
				if(type==2){
					this.$refs.add.open(2,id,isNew)
				}else{
					this.$refs.add.open(1)
				}
			},
			sub(){
				this.$store.dispatch('serviceArea/updateAddressData',{address:this.seleData.address,rows:this.seleData.rows})
				this.$emit("addressChange",this.seleData)
				this.close()
			},
			seleChange(data){
				this.seleData=data
			},
			open() {
				// this.seleData = JSON.parse(JSON.stringify(this.$store.state.serviceArea.addressData))
				this.listData=[]
				this.list()
				this.show = true
			},
			close() {
				this.show = false
			},
			async list(){
				let {data} = await listV2()
				
				this.listData=data
				if(!data){
					this.noData=true
				}else{
					this.noData=false
				}
			},
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.noData{
		width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.noData img{
		width: 64px;
		height: 64px;
		display: block;
		margin: 0 auto;
	}
	.noData .text{
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		margin-top: 10px;
	}
	.but {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.but div{
		width: 200px;
		height: 44px;
		background: #56AB7B;
		border-radius: 24px;
		margin: 0 auto;
		margin-top: 16px;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F5F5F5;
		font-weight: 400;
		text-align: center;
		line-height: 44px;
		cursor: pointer;
		border: 1px solid #56AB7B;
	}
	.but div:nth-child(1){
		background: #fff;
		border: 1px solid #56AB7B;
		color: #56AB7B;
	}
	.list {
		height: 360px;
		overflow-y: scroll;
	}

	.seleAddressWap >>>.el-dialog__body {
		padding: 0 16px 24px 16px;
	}

	.seleAddressWap >>>.el-dialog {
		width: 600px;
		background: #FFFFFF;
		border-radius: 10px;
	}

	.seleAddressWap >>>.el-dialog__header {
		padding: 0;
	}

	.seleAddressWap >>>.el-dialog__header div {
		padding: 0 16px;
		height: 53px;
		display: flex;
		justify-content: space-between;
		border: 0.5px solid rgba(0, 0, 0, .1);
	}

	.seleAddressWap >>>.el-dialog__header .titleLeft {
		display: block;
		height: 53px;
		border: none;
		padding: 0;
	}

	.seleAddressWap >>>.el-dialog__header .titleLeft div:nth-child(1) {
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		line-height: 53px;
		font-weight: 500;
		border: none;
		display: block;
		padding: 0;
		height: 53px;
	}

	.seleAddressWap >>>.el-dialog__header .titleRight {
		width: 53px;
		height: 53px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		padding: 0;
	}

	.seleAddressWap >>>.el-dialog__header .titleRight div {
		width: 24px;
		height: 24px;
		background: #F2F3F5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
		border: none;
		padding: 0;
	}

	.seleAddressWap >>>.el-dialog__header .titleRight img {
		width: 12px;
		height: 12px;
		display: block;
	}
</style>
