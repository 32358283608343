<template>
	<div>
		<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :before-close="close">
			<div slot="title">
				<div class="titleLeft">
					{{type==1?i18n('44'):i18n('45')}}
				</div>
				<div class="titleRight" @click="close">
					<img src="@/assets/close.png" alt="">
				</div>
			</div>
			<div class="list">
				<div class="item">
					<div class="label">
						{{i18n('46')}}：
					</div>
					<input type="text" v-model="params.name" class="input">
				</div>
				<div class="item">
					<div class="label">
						{{i18n('47')}}：
					</div>
					<input type="number" v-model="params.phone" class="input">
				</div>
				<!-- <div class="item">
					<div class="label">
						{{i18n('48')}}：
					</div>
					<el-select v-model="params.city_id" :placeholder="i18n('49')" class="input">
						<el-option v-for="item in city" :key="item.base.id" :label="item.base.name" :value="item.base.id">
						</el-option>
					</el-select>
				</div> -->
				<div class="item">
					<div class="label">
						{{i18n('122')}}：
					</div>
					<input type="text" v-model="params.detail_address" class="input inputJC" @click="openMap">
					<!-- <textarea name="" id="" cols="30" rows="10" class="input" v-model="params.detail_address"></textarea> -->
				</div>
				<div class="item itemTextarea">
					<div class="label">
						{{i18n('50')}}：
					</div>
					<!-- <input type="text" v-model="userInfo.birthday" class="input"> -->
					<textarea name="" id="" cols="30" rows="10" class="input" v-model="params.house_number"></textarea>
				</div>
			</div>
			<div class="labelList">
				<div :class="params.label==0?'bq bqAc':'bq'" @click="params.label=0">
					{{i18n('51')}}
				</div>
				<div :class="params.label==1?'bq bqAc':'bq'" @click="params.label=1">
					{{i18n('52')}}
				</div>
				<div :class="params.label==2?'bq bqAc':'bq'" @click="params.label=2">
					{{i18n('53')}}
				</div>
				<div class="mr" @click="defaultChange">
					<!-- is_default -->
					<img src="@/assets/sele.png" alt="" v-if="params.is_default==1">
					<img src="@/assets/seleNo.png" alt="" v-else>
					{{i18n('54')}}
				</div>
			</div>
			<div class="but">
				<div class="" @click="close">
					{{i18n('55')}}
				</div>
				<div class="" @click="edit">
					{{i18n('56')}}
				</div>
			</div>
		</el-dialog>
		<mapWap ref="mapWap" @seleLocation="seleLocation"></mapWap>
	</div>

</template>

<script>
	import {
		edit,
		editV2,
		info,
		infoV2
	} from "@/api/address.js"

	import mapWap from './mapWap.vue'
	export default {
		name: "add",
		components: {
			mapWap
		},
		data() {
			return {
				show: false,
				type: 1,
				params: {
					id: 0,
					user_id: 0,
					name: "",
					phone: "",
					phone_spare: "",
					telegran: "",
					whats_app_user: "",
					line: "",
					openId: "",
					city: "",
					detail_address: "",
					label: 0, //0.家 1公司 2其它
					is_default: 0,
					lal: "",
					create_time: "",
					messenger: "",
					viber: "",
					instagram: "",
					wx: "",
					city_id: 0,
					other_info: '',
					landmark_image: '',
					house_number: "", //门牌号
				},
				dwDz: {
					city: '',
					lat: 0,
					lon: 0,
					show: false,
					province: "",
					district: "",
					street: "",
					detail_address: ""
				},
				isNew: false
			}
		},
		methods: {
			seleLocation(data) {
				// console.log()
				this.params.longitude = data.longitude
				this.params.latitude = data.latitude
				this.params.detail_address = data.vicinity + '-' + data.name
			},
			openMap() {
				//打开地图
				this.$refs.mapWap.open()
			},
			verification() {
				if (this.params.name == '') {
					this.$message.error(this.i18n('59'))
					return false
				}
				// if (this.params.city_id == 0) {
				// 	this.$message.error(this.i18n('60'))
				// 	return false
				// } else {
				// 	this.city.map(item => {
				// 		if (item.base.id == this.params.city_id) {
				// 			this.params.city = item.base.name
				// 		}
				// 	})
				// }
				// if (this.params.detail_address.length < 5) {
				// 	this.$message.error(this.i18n('61'))
				// 	return false
				// }
				if (this.params.phone.length != 10 && this.params.phone.length != 11) {
					this.$message.error(this.i18n('62'))
					return false
				}
				let params = JSON.parse(JSON.stringify(this.params))
				if (params.phone.length == 10) {
					params.phone = '0' + params.phone
				}
				params.phone = '63' + params.phone
				return params
			},
			async edit() {
				let params = this.verification()
				if (params) {
					console.log('params')
					console.log(params)
					if (params.longitude) {
						await editV2(params)
					} else {
						await edit(params)
					}
					if (this.type == 2) {
						this.$message.success(this.i18n('63'))
					} else {
						this.$message.success(this.i18n('64'))
					}
					this.$emit("czSuccess")
					this.close()
				}
			},
			async open(type, id, isNew) {
				this.params = {
					id: 0,
					user_id: 0,
					name: "",
					phone: "",
					phone_spare: "",
					telegran: "",
					whats_app_user: "",
					line: "",
					openId: "",
					city: "",
					detail_address: "",
					label: 0, //0.家 1公司 2其它
					is_default: 0,
					lal: "",
					create_time: "",
					messenger: "",
					viber: "",
					instagram: "",
					wx: "",
					city_id: 0,
					other_info: '',
					landmark_image: ''
				}
				this.type = type
				if (id) {
					isNew == '0' ? this.isNew = false : this.isNew = true;
					let data = false
					if (this.isNew) {
						data = await infoV2({
							id: Number(id)
						})
						data = data.data.address
					} else {
						data = await info({
							id: Number(id)
						})
						data = data.data
					}
					data.phone = data.phone.substring(2)
					this.params = data
				}

				this.show = true
			},
			close() {
				this.show = false
			},
			defaultChange() {
				if (this.params.is_default == 0) {
					this.params.is_default = 1
				} else {
					this.params.is_default = 0
				}
			},
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			},
			city() {
				let area = this.$store.state.serviceArea.area
				console.log('area')
				console.log(area)
				area.map(item => {
					if (this.lang == 'zh') {
						item.base.name = item.base.zh_name
					}
					if (this.lang == 'en') {
						item.base.name = item.base.en_name
					}
					if (this.lang == 'vie') {
						item.base.name = item.base.vi_name
					}
				})
				return area
			},
		},
		mounted() {
			this.params.city_id = this.$store.state.serviceArea.seleLoca.base.id
			if (this.lang == 'zh') {
				this.params.city = this.$store.state.serviceArea.seleLoca.base.zh_name
			}
			if (this.lang == 'en') {
				this.params.city = this.$store.state.serviceArea.seleLoca.base.en_name
			}
			if (this.lang == 'vie') {
				this.params.city = this.$store.state.serviceArea.seleLoca.base.vi_name
			}
		}
	};
</script>

<style scoped>
	.but {
		padding: 0 70px;
		margin-top: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.but div {
		cursor: pointer;
	}

	.but div:nth-child(1) {
		width: 197px;
		height: 44px;
		border: 1px solid rgba(86, 171, 123, 1);
		border-radius: 22.5px;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #56AB7B;
		font-weight: 500;
	}

	.but div:nth-child(2) {
		width: 226px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		line-height: 44px;
	}

	.labelList {
		display: flex;
		align-items: center;
	}

	.labelList .bq {
		height: 32px;
		border-radius: 4px;
		line-height: 32px;
		padding: 0 20px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
		margin-right: 24px;
		background: rgba(86, 171, 123, 0.10);
		cursor: pointer;
	}

	.labelList .bqAc {
		background: #56AB7B;
		color: #fff;
	}

	.mr {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 14px;
		font-weight: 400;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.mr img {
		width: 16px;
		height: 16px;
		display: block;
		margin-right: 6px;
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.item {
		display: flex;
		align-items: center;
		margin-bottom: 24px;
	}

	.itemTextarea {
		width: 100%;
		align-items: baseline;
	}

	.item .label {
		width: 100px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}

	.item .input {
		width: 180px;
		height: 32px;
		border: 1px solid rgba(170, 181, 195, 1);
		border-radius: 16px;
		padding: 0 16px;
		box-sizing: border-box;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		line-height: 22px;
		font-weight: 400;
	}

	.item .inputJC {
		width: 380px;
	}

	.itemTextarea .input {
		flex: 1;
		height: 79px;
		padding: 5px 16px;
	}

	>>>.el-dialog__body {
		padding: 0 32px 40px 32px;
	}

	>>>.el-dialog {
		width: 662px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	>>>.el-dialog__header {
		padding: 0 32px;
	}

	>>>.el-dialog__header div {
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	>>>.el-dialog__header .titleLeft {
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		font-weight: 500;
	}

	>>>.el-dialog__header .titleRight {
		width: 24px;
		height: 24px;
		background: #F2F3F5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
	}

	>>>.el-dialog__header .titleRight img {
		width: 12px;
		height: 12px;
		display: block;
	}

	>>>.el-input__icon {
		line-height: 30px;
	}

	>>>.el-input__inner {
		border: none;
		height: 30px;
	}

	>>>.el-input__inner:focus {
		border: none !important;
	}

	>>>.el-select .el-input.is-focus .el-input__inner {
		border: none !important;
	}
</style>
